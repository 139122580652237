.Energiser-Container{
    display: inline-flex;
    position: absolute;
    justify-content: center;
    align-items: center;
}

.Energiser {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
}
