//////////////////////////////////////////////////
// GLOBAL GENERIC STYLES
$background-colour: #282C34;
$pacman-wall-colour: #2120D2;
$pacman-yellow: #F4D147;

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page {
  height: 90vh;
}

.App-Link {
  color: #61dafb;
}

.Page {
  min-height: 100vh;
  background-color: $background-colour;
}

.Link {
  text-decoration: none;
}

.Link:visited {
  text-decoration: none;
}

.Pixel-Font {
  font-family: PublicPixel, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
//////////////////////////////////////////////////
